(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-selector-goal-count/assets/javascripts/betslip-selector.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-selector-goal-count/assets/javascripts/betslip-selector.js');
"use strict";

const {
  BetslipCouponType
} = svs.components.tipsen.engine.constants;
const {
  useBetslipContext
} = svs.components.tipsen.betslipComponents;
const {
  ExternaSystemspel,
  Standard
} = svs.components.tipsen.betslipSelectorGoalCount.betslips;
const BetslipSelector = () => {
  const {
    couponType
  } = useBetslipContext();
  let BetslipLocal;
  switch (couponType) {
    case BetslipCouponType.Standard:
      BetslipLocal = Standard;
      break;
    case BetslipCouponType.ExternaSystemspel:
      BetslipLocal = ExternaSystemspel;
      break;
    default:
      BetslipLocal = null;
      throw new Error("No betslip found for couponType: ".concat(couponType));
  }
  return React.createElement(BetslipLocal, null);
};
setGlobal('svs.components.tipsen.betslipSelectorGoalCount.BetslipSelector', BetslipSelector);

 })(window);